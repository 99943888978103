html {
    line-height: 1.3;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba($black, 0);
    margin: 0;
}

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    min-width: 320px;
    color: $main-text;
    direction: ltr;
    text-align: left;
    background-color: #fff;
}

main {
    max-width: 1400px;
    margin: 0 auto;
}

.theme-wrap {
    width: 100%;
    overflow-x: hidden !important;
    transition: opacity .26s ease;

    &.show-theme {
        opacity: 1 !important;
    }
}

section {
    $padding-top: (320px: 65px, 1200px: 125px);
    $padding-bottom: (320px: 65px, 1200px: 125px);
    @include poly-fluid-sizing('padding-top', $padding-top);
    @include poly-fluid-sizing('padding-bottom', $padding-bottom);

    &.gallery {
        $margin-bottom: (320px: 65px, 1200px: 125px);
        @include poly-fluid-sizing('margin-bottom', $margin-bottom);
    }
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.background-center {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.is-on-top {
    position: relative;
    z-index: 2;
}

.row-center {
    justify-content: center;
}

.full-screen {
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}


/*
|----------------------------------------------------------------
|  Padding and Margins
|----------------------------------------------------------------
*/

.no-padding {
    padding: 0;
}

.no-padding-top {
    padding-top: 0;
}

.no-padding-bottom {
    padding-bottom: 0;
}

.no-margin {
    margin: 0;
}

/*
|----------------------------------------------------------------
|  Positioning
|----------------------------------------------------------------
*/

.is-relative {
    position: relative;
}

.is-absolute {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.has-absolute-before {
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

.has-absolute-after {
    position: relative;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

/*
|----------------------------------------------------------------
|  Trigger icons for global nav and mobile pilots template
|----------------------------------------------------------------
*/
.subnav-icon {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 20px;
    position: absolute;
    right: 0;
    top: 16.5px;
    cursor: pointer;

    &:before,
    &:after {
        content: "";
        display: block;
        background: $primary;
        transition: all 0.3s ease;
        position: absolute;
    }

    &:before {
      width: 100%;
      height: 3px;
      top: 50%;
      opacity: 1;
      margin-top: -1.5px;
    }

    &:after{
      width: 3px;
      height: 100%;
      left: 50%;
      margin-left: -1.5px;
    }
}
