/*
|----------------------------------------------------------------
|  Content
|----------------------------------------------------------------
*/

.contact {

    .main-heading {
        $margin-bottom: (320px: 30px, 1200px: 45px);
        @include poly-fluid-sizing('margin-bottom', $margin-bottom);
    }

    p {
        font-weight: $semi;
        $font-size: (320px: 18px, 1200px: 20px);
        @include poly-fluid-sizing('font-size', $font-size);
    }
}

/*
|----------------------------------------------------------------
|  Contact Details
|----------------------------------------------------------------
*/
.contact-details {
    position: relative;
    background: $blue;

    li {
        $font-size: (320px: 16px, 1200px: 18px);
        @include poly-fluid-sizing('font-size', $font-size);
        margin-bottom: 50px;

        &:last-child {
            margin: 0;
        }
    }

    img {
        width: 40px;
        display: block;
        margin: 0 auto 18px;
    }

    li,
    a,
    address {
        color: $white;
    }
}

/*
|----------------------------------------------------------------
|  Map
|----------------------------------------------------------------
*/
.google-map {
    $height: (320px: 300px, 1200px: 550px);
    @include poly-fluid-sizing('height', $height);
}
