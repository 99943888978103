.page-banner {
    overflow: hidden;
    padding: 0;

    &:before {
        bottom: 0;
        background-color: $blue;
        opacity: .65;
    }

    .nav-col {
        z-index: 3;
    }

    .banner-content {
        $height: (320px: 300px, 765px: 450px, 1200px: 800px);
        @include poly-fluid-sizing('height', $height);
    }

    &.home-banner {

        .banner-content {
            $padding-top: (320px: 45px, 1200px: 95px, 1600px: 150px, 2000px: 200px);
            $padding-bottom: (320px: 55px, 1200px: 105px, 1600px: 150px, 2000px: 200px);
            @include poly-fluid-sizing('padding-top', $padding-top);
            @include poly-fluid-sizing('padding-bottom', $padding-bottom);
            height: auto;

            svg {

                path {

                    +path {
                        fill: $primary;
                    }
                }
            }

            .last-updated {
                color: $white;
                font-size: 10px;
            }
        }
    }

    .gauge-3, .gauge-4 {
        margin-top: 40px;
    }
}

header {
    position: relative;
    background: $primary;
}
