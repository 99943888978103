//Primary Theme Colours
$white : #fff;
$grey : #a1a1a1;
// $dark-grey : #fff;
$blue : #14283a;
$primary : #508391;
$headings : #508391;
$black : #000;

//Typography:
$main-text : $blue;
$main-text-link-colour : $blue;

//Lists
$custom-list-dot : #2684fe;
$custom-list-number-colour : #2684fe;


// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
