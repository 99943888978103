.gn--trigger {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0;
    right: 0;
    padding: 0;
    outline: none;
    border: none;
    transition: all 0.37s ease;
    z-index: 99;

    &:focus {
        outline: 0;
    }

    .burger {
        width: 25px;
        height: 2px;
        background: $white;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        transition: all 0.37s ease-in-out;

        &:first-child{
            top: 20px
        }

        &:last-child {
            top: auto;
            bottom: 18px;
        }
    }

    &.burger-active {

        .burger {
            width: 35px;

            &:first-child {
                transform: rotate(-135deg);
                top: 30px;
                left: 12px;
            }

            &:nth-child(2) {
                opacity: 0;
                left: -25px;
            }

            &:last-child{
                transform: rotate(135deg);
                left: 12px;
                top: 30px;
            }
        }
    }
}
