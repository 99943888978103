// --------------------------------------------------------------------------
// Global
// --------------------------------------------------------------------------
.is-uppercase {
    text-transform: uppercase;
}

b,
strong {
    font-weight: $semi;
    color: #14283a;
}

// em {
//     @include graphikRegularItalic();
// }

.text-center {
    text-align: center;
}


// --------------------------------------------------------------------------
// Headings
// --------------------------------------------------------------------------
h1, h2, h3, h4, h5, h6 {
    margin: 0 0 20px 0;
    font-weight: $semi;
    color: $headings;
}


h1, h2,
.h2 {
    $font-size: (320px: 35px, 1200px: 50px);
    @include poly-fluid-sizing('font-size', $font-size);
}

.main-heading {
    text-transform: uppercase;

    br {
        display: none;
    }
}

/*
|----------------------------------------------------------------
|  Color
|----------------------------------------------------------------
*/
.is-blue {
    color: $blue;
}

/*
|----------------------------------------------------------------
|  Paragraph
|----------------------------------------------------------------
*/
 p {
    margin: 0;
    margin-bottom: 25px;
    line-height: 1.5;
    $font-size: (320px: 16px, 1200px: 18px);
    @include poly-fluid-sizing('font-size', $font-size);

    &.intro-content {
        font-weight: $semi;
        color: #507d89;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &:empty {
        display: none;
    }

    a {
        color: $main-text-link-colour;
        border-bottom: 2px solid #507d89;
    }

 }

/*
|----------------------------------------------------------------
|  Links
|----------------------------------------------------------------
*/

a {
    text-decoration: none;
    -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
    transition: all 275ms ease;

    &:hover, &:focus, &:active {
        text-decoration: none;
        outline: 0 !important;
    }
}

.read-more {
    margin-top: 12px;
    font-weight: $bold;
    display: inline-flex;
}
