/*
|----------------------------------------------------------------
|  Lists
|----------------------------------------------------------------
*/

ol,
ul,
dl {
    padding: 0;
    margin: 0;
    list-style: none;
}

li {
    margin-bottom: 25px;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

.custom-bullet-lists {

    ul {
        padding-left: 25px;
        margin-bottom: 25px;

        li {
            position: relative;

            &:before {
                content: '•';
                font-size: 18px;
                color: $custom-list-dot;
                position: absolute;
                top: -1px;
                left: -15px;
            }
        }
    }

    ol {
        padding-left: 25px;
        margin-bottom: 25px;
        counter-reset: my-counter;

        li {

            &:before {
                content: counter(my-counter) '.';
                counter-increment: my-counter;
                top: 1px;
                left: -5px;
                position: relative;
                color: $custom-list-number-colour;
            }
        }
    }
}
