/*
 |----------------------------------------------------------------
 |  Global Navigation
 |----------------------------------------------------------------
 */

//================================
//Navigation Wrap
//================================
.gn-wrap {
    background: $primary;
    width: 0;
    position: fixed;
    top:0;
    left: 0;
    bottom: 0;
    z-index:15;
    transition: width 300ms cubic-bezier(.55,.21,0,.93) 350ms;

    &.expand-wrap {
        min-height: 100vh;
        width: 100vw;
        transition: width 300ms cubic-bezier(.55,.21,0,.93) 375ms;
    }
}

//================================
//Global Nav
//================================
.gn {
    width: 0;
    position: fixed;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch;
    top:60px;
    left: 0;
    bottom: 0;
    z-index: 10;
    transition: width 400ms cubic-bezier(.55,.21,0,.93) 425ms;
    background: $white;

    &.gn--open {
        width: 100vw;
    }

    //================================
    //Sub Navigation
    //================================
    .sub-menu {
        display: none;
        padding: 0;
        width: 100vw;
        border-top: 1px solid grey;
        border-bottom: 1px solid grey;
        margin: 8px 0 12px;

        .menu-item {
            font-size: 14px;
            opacity: 1;
            padding-left: 25px;
            margin-bottom: 15px;

            &:first-child {
                padding-top: 10px;
            }

            &:last-child {
                padding-bottom: 10px;
            }
        }
    }
}


//=========================================================================
//Navigation menu item (Not nested to caputire both menus)
//=========================================================================
.gn--items {
    padding: 12px 0 0;

    .menu-item {
        font-weight: 500;
        $map: (320px: 18px, 768px: 20px, 992px: 20px, 1300px: 24px);
        @include poly-fluid-sizing('font-size', $map);
        position: relative;
        opacity: 0;
        transition: opacity .35s ease;
        line-height: 1.2;
        @include montserratSemiBold();
        transition: all .3s ease;
        margin-bottom: 5px;

        &.active {
            background: $primary;

            >a {
                color: $white !important;
            }

            .subnav-icon:after,
            .subnav-icon:before {
                background: $white;
            }

            .sub-menu {
                background: $white;
            }
        }

        &.menu-item-has-children {

           >a {
                cursor: default !important;
            }
        }

        // &.current-menu-parent,
        // &.current-page-parent,
        // &.active {

        //     > a {
        //         color: $dark;
        //     }
        // }

        // .subnav-icon {
        //     width: 15px;
        //     height: 15px;
        //     display: inline-block;
        //     margin-right: 20px;
        //     position: absolute;
        //     right: 0;
        //     top: 16.5px;
        //     cursor: pointer;

        //     &:before,
        //     &:after {
        //         content: "";
        //         display: block;
        //         background: $primary;
        //         transition: all 0.3s ease;
        //         position: absolute;
        //     }

        //     &:before {
        //       width: 100%;
        //       height: 3px;
        //       top: 50%;
        //       opacity: 1;
        //       margin-top: -1.5px;
        //     }

        //     &:after{
        //       width: 3px;
        //       height: 100%;
        //       left: 50%;
        //       margin-left: -1.5px;
        //     }
        // }

        a {
            display: block;
            color: $main-text;
            transition: all 250ms ease;
            padding: 15px 25px;
            cursor: pointer;
        }

        &.slide-down-sub-nav {

            .subnav-icon {

                &:after{
                    transform: rotate(90deg);
                }

                &:before {
                    transform: rotate(90deg);
                    opacity: 0;
                }
            }

        }

        &.menu-item-has-children > a {
            //pointer-events: none;
            position: relative;
        }

        &.show-item {
            opacity: 1;
            top: 0;
            transition: opacity .35s ease 700ms;
        }
    }
}

.mobile-search {
    opacity: 0;
    transition: opacity .35s ease;
    margin: 0 25px;

    &.show-item {
        opacity: 1;
        transition: opacity .35s ease 700ms;
    }

    .search-bar {
        width: 100%;
        border: 1px solid $primary;
        padding: 12px;
    }
}
