/*
|----------------------------------------------------------------
| Heading
|----------------------------------------------------------------
*/

.scg-heading-col {

    .icon-wrap {
        flex-basis: 50px;
        max-width: 50px;
        height: 50px;
        padding: 13px;
        background: $primary;
        margin-right: 15px;
    }

    .heading {
        flex: 1;
        color: $blue;
        $font-size: (320px: 18px, 1200px: 20px);
        @include poly-fluid-sizing('font-size', $font-size);
        margin: 0;
        text-transform: capitalize;
    }
}

/*
|----------------------------------------------------------------
| Table
|----------------------------------------------------------------
*/

.scg-list {
    margin: 30px 0 0 25px;

    li {
        border-bottom: 1px solid $grey;
        color: $blue;
        $font-size: (320px: 16px, 1200px: 18px);
        @include poly-fluid-sizing('font-size', $font-size);

        &.charter-item {
           font-weight: $semi;
        }

        .heading {
            font-weight: $semi;
            color: $blue;
        }

        .charter-heading {
            display: inline-block;
        }

        .website {

            a {
                color: $blue;
            }
        }

        span,
        p {
            display: block;
            color: $primary;
        }
    }

    &:not(:first-of-type) {
        $margin-top: (320px: 55px, 1200px: 70px);
        @include poly-fluid-sizing('margin-top', $margin-top);
    }
}
