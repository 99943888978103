/*
|----------------------------------------------------------------
|  Pilots Intro
|----------------------------------------------------------------
*/

.pilots-intro {

    p {
        font-weight: $semi;
        $font-size: (320px: 18px, 1200px: 24px);
        @include poly-fluid-sizing('font-size', $font-size);
    }
}


/*
|----------------------------------------------------------------
|  Flexible Content
|----------------------------------------------------------------
*/

.pilot-details {

    .layout-row {
        margin-bottom: 30px;

        &:last-of-type {
            margin: 0;
        }
    }

    .content {
        display: none;
        background: #ebebeb;
        padding: 15px 12px;
    }

    .scg-heading-col {

        .subnav-icon {
            margin: 0;

            &.is-active {

                &:after{
                    transform: rotate(90deg);
                }

                &:before {
                    transform: rotate(90deg);
                    opacity: 0;
                }
            }
        }
    }

    .heading {
        $font-size: (320px: 13px, 560px: 17px, 1200px: 20px);
        @include poly-fluid-sizing('font-size', $font-size);
        padding-right: 25px;
    }

    .scg-list {
        margin: 0;

        li {
            margin-bottom: 12px;

            // p,
            // span {
            //     color: #696969;
            // }
        }
    }
}

.table-body {

    li {
        border-bottom: 1px solid #a1a1a1;
    }

    .heading {
        font-weight: $semi;
        font-size: 14px;
        color: $blue;
        padding-right: 3px;
    }
}


/*
|----------------------------------------------------------------
|  Downloads
|----------------------------------------------------------------
*/
.downloads {
    $margin-top: (768px: 55px, 1200px: 85px);
    @include poly-fluid-sizing('margin-top', $margin-top);

    li {
        $font-size: (320px: 18px, 1200px: 20px);
        @include poly-fluid-sizing('font-size', $font-size);
        font-weight: $semi;
        text-align: center;
    }

    a {
        color: $white;
        padding: 12px 30px;
        margin-bottom: 30px;

        &.turquoise {
            background: $primary;
        }

        &.blue {
            background: $blue;
        }
    }
}
