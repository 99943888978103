/*
|----------------------------------------------------------------
|  Content
|----------------------------------------------------------------
*/

.hp-content {

    .content-col {

        p {
            @include montserratSemiBold();
            $font-size: (320px: 18px, 1200px: 24px);
            @include poly-fluid-sizing('font-size', $font-size);
        }
    }
}
