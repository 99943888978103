@include media-breakpoint-up(md) {

    /*
    |----------------------------------------------------------------
    | Typography
    |----------------------------------------------------------------
    */
    .main-heading {

        br {
            display: block;
        }
    }

    /*
    |----------------------------------------------------------------
    | Template: Homepage
    |----------------------------------------------------------------
    */
    .page-banner {

        .banner-content {

            .last-updated {
                text-align: right;
            }
        }
    }

    /*
    |----------------------------------------------------------------
    | Template: Pilots
    |----------------------------------------------------------------
    */

    .pilot-details {

        .layout-row {
            margin-bottom: 70px;
        }

        .content {
            display: block !important;
            background: transparent;
            padding: 0;
            margin: 15px 0 0 85px;

            &.cell-count-3 {

                .common-cell {
                    flex-basis: 33.3333333%;
                    max-width: 33.3333333%;
                }
            }

            &.cell-count-4 {

                .common-cell {
                    flex-basis: 25%;
                    max-width: 25%;
                }
            }

            &.cell-count-5 {

                .common-cell {
                    flex-basis: 20%;
                    max-width: 20%;
                }
            }
        }

        .common-cell {
            padding-right: 20px;
        }

        .table-head {
            border-bottom: 1px solid #a1a1a1;
            margin-bottom: 25px;

            .heading {
                color: $blue;
                font-weight: $semi;
                $font-size: (768px: 14px, 1200px: 18px);
                @include poly-fluid-sizing('font-size', $font-size);
            }
        }
    }

    .downloads {

        li {
            margin: 0 12px;
        }
    }

    /*
    |----------------------------------------------------------------
    | Template: Reporting and Payment
    |----------------------------------------------------------------
    */
    .table-wrap {

        h3 {
            margin: 0 0 -4px !important;
        }



        .tr {
            grid-template-columns: 1fr 1fr 1fr 1fr;

            .cell {

                &:first-child {

                    p {
                        font-size: 13px;
                    }
                }
            }
        }


        .cell {

            p {
                font-size: 15px;
            }

            &.th {

                p {
                    font-size: 13px;
                }
            }

            &:nth-child(4n + 2) {
                display: block;
            }
        }
    }

    /*
    |----------------------------------------------------------------
    | Template: Airport Services
    |----------------------------------------------------------------
    */
   .fees-blk {

        .box-2 {
            margin-top: 0;
        }
    }

    /*
    |----------------------------------------------------------------
    | Template: Contact
    |----------------------------------------------------------------
    */
    .contact-details {

        ul {
            display: flex;
            justify-content: center;
        }

        li {
            margin: 0;
            flex-basis: 33.33333333%;
            max-width: 33.33333333%;
        }
    }

    /*
    |----------------------------------------------------------------
    | Component: Gallery
    |----------------------------------------------------------------
    */
    .gallery {

        .image-slider {
            display: flex;
            flex-wrap: wrap;

            .img {
                flex-basis: 31.3333333333%;
                max-width: 31.3333333333%;
                padding-bottom: 31.3333333333%;

                &:nth-child(3n + 2) {
                    margin: 0 2%;
                }

                &:nth-child(n+4) {
                    margin-top: 25px;
                }
            }

            &:after {
                content: '';
            }
        }
    }

    .gform_fields {
        display: flex;
        flex-wrap: wrap;
    }

    .gfield {
        flex-basis: 100%;
        max-width: 100%;
    }

    .gf-half {
        flex-basis: 48%;
        max-width: 48%;
    }

    .gf-left {
        margin-right: 4%;
    }

    /*
    |----------------------------------------------------------------
    | Page: Search
    |----------------------------------------------------------------
    */
    .search-results {

        .entry-image {
            height: auto;
            flex-basis: 30%;
            max-width: 30%;
            margin: 0 5% 0 0;
        }

        .entry-summary {
            flex-basis: 65%;
            max-width: 65%;
        }
    }

    /*
    |----------------------------------------------------------------
    | Component: Content Table
    |----------------------------------------------------------------
    */
    .scg-list {
        margin: 15px 0 0 85px;

        li {

            .is-right {
                float: right;
            }
        }
    }

    /*
    |----------------------------------------------------------------
    |  IATS Form
    |----------------------------------------------------------------
    */
    // #IATS_PaymentSectionDiv {
    //     display: flex;
    //     justify-content: space-between;
    //     flex-wrap: wrap;

    //     .IATS_PaymentSectionNote {
    //         flex-basis: 100%;
    //         max-width: 100%;
    //     }

    //     .IATS_PaymentItemDiv {
    //         flex-basis: 48%;
    //         max-width: 48%;
    //     }
    // }

    // #IATS_PaymentItemDiv_Item2 {
    //     margin-top: auto;
    // }

    // #IATS_PaymentItemDiv_EMail {
    //     flex-basis: 100% !important;
    //     max-width: 100% !important;

    //     label,
    //     .IATS_PaymentInputField {
    //         width: 48%;
    //     }
    // }

    /*
    |----------------------------------------------------------------
    | Footer
    |----------------------------------------------------------------
    */
    .footer {
        $padding-left: (768px: 30px, 1200px: 50px);
        $padding-right: (768px: 30px, 1200px: 50px);
        @include poly-fluid-sizing('padding-left', $padding-left);
        @include poly-fluid-sizing('padding-right', $padding-right);


        .social-row {
            background: transparent;
        }

        .social-col {

            hr {
                bottom: -7vw;
                height: 18vw;
                transform: skewY(8.5deg) translateY(-50%) translateZ(0);
            }
        }

        .ftr-hr-end {
            transform: skewY(8.5deg) translateY(-50%) translateZ(0);
        }
    }
}
