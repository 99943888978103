/*
 |----------------------------------------------------------------
 |  Skew Base
 |----------------------------------------------------------------
 */
.section-skew {
    background-clip: content-box;
    backface-visibility: hidden;
    padding-bottom: .0625rem;
    padding-top: .0625rem;
    z-index: 1;
    border: none;
    display: block;
    height: 9vw;
    left: 0;
    margin: 0;
    max-width: none;
    position: absolute;
    right: 0;
    background: $primary;
    top: -1vw;
    transform: skewY(3.6deg) translateY(-50%) translateZ(0);
    width: 100vw;
    outline: 1px solid transparent;
    will-change: transform;
    -webkit-perspective: 1000;

    &.reversed {
        transform: skewY(-3.6deg) translateY(-50%);
    }

    &.keep-to-bottom {
        bottom: -8vw;
        top: auto;
    }
}

// .has-skew {
//     overflow: hidden;
//     padding-bottom: 5rem;
//     padding-top: 5rem;
//     position: relative;
// }

// .has-bottom-skew {
//     overflow: hidden;
//     padding-bottom: 5rem;
//     position: relative;
// }

// .has-upper-skew {
//     overflow: hidden;
//     padding-top: 5rem;
//     position: relative;
// }
