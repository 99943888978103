// stylelint-disable at-rule-no-vendor-prefix, declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix

// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css


// Document
//
// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 2. Change the default font family in all browsers.
// 3. Correct the line height in all browsers.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
//    we force a non-overlapping, non-auto-hiding scrollbar to counteract.
// 6. Change the default tap highlight to be completely transparent in iOS.

*,
*::before,
*::after {
  box-sizing: border-box; // 1
}



// IE10+ doesn't honor `<meta name="viewport">` in some cases.
@at-root {
  @-ms-viewport {
    width: device-width;
  }
}

// stylelint-disable selector-list-comma-newline-after
// Shim for "new" HTML5 structural elements to display correctly (IE10, older browsers)
article, aside, figcaption, figure, footer, main, section {
  display: block;
}



// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
//
// Credit: https://github.com/suitcss/base
[tabindex="-1"]:focus {
  outline: 0 !important;
}


// Abbreviations
//
// 1. Remove the bottom border in Firefox 39-.
// 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
// 3. Add explicit cursor to indicate changed behavior.
// 4. Duplicate behavior to the data-* attribute for our tooltip plugin

abbr[title],
abbr[data-original-title] { // 4
  text-decoration: underline; // 2
  text-decoration: underline dotted; // 2
  cursor: help; // 3
  border-bottom: 0; // 1
}

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit;
}

blockquote {
  margin: 0 0 1rem;
}


// And undo these styles for placeholder links/named anchors (without href)
// which have not been made explicitly keyboard-focusable (without tabindex).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;

  @include hover-focus {
    color: inherit;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}

//
// Figures
//

figure {
  // Apply a consistent margin strategy (matches our type styles).
  margin: 0 0 1rem;
}


//
// Images and content
//

img {
  vertical-align: middle;
  border: none;
}

svg:not(:root) {
  overflow: hidden; // Hide the overflow in IE
}


//
// Tables
//

table {
  border-collapse: collapse; // Prevent double borders
}

caption {
  padding-top: $table-cell-padding;
  padding-bottom: $table-cell-padding;
  color: $table-caption-color;
  text-align: left;
  caption-side: bottom;
}

th {
  // Matches default `<td>` alignment by inheriting from the `<body>`, or the
  // closest parent with a set `text-align`.
  text-align: inherit;
}


// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
button,
html [type="button"], // 1
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; // 2
  border: none;
  background: transparent;
  outline: 0;
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}



progress {
  vertical-align: baseline; // Add the correct vertical alignment in Chrome, Firefox, and Opera.
}

// Correct the cursor style of increment and decrement buttons in Chrome.
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  // This overrides the extra rounded corners on search inputs in iOS so that our
  // `.form-control` class can properly style them. Note that this cannot simply
  // be added to `.form-control` as it's not specific enough. For details, see
  // https://github.com/twbs/bootstrap/issues/11586.
  outline-offset: -2px; // 2. Correct the outline style in Safari.
  -webkit-appearance: none;
}

//
// Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
//

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

//
// 1. Correct the inability to style clickable types in iOS and Safari.
// 2. Change font properties to `inherit` in Safari.
//

::-webkit-file-upload-button {
  font: inherit; // 2
  -webkit-appearance: button; // 1
}

//
// Correct element displays
//

output {
  display: inline-block;
}

summary {
  display: list-item; // Add the correct display in all browsers
  cursor: pointer;
}

template {
  display: none; // Add the correct display in IE
}

// Always hide an element with the `hidden` HTML attribute (from PureCSS).
// Needed for proper display in IE 10-.
[hidden] {
  display: none !important;
}
