.default-template {

    .main-heading {
        $margin-bottom: (320px: 30px, 1200px: 62px);
        @include poly-fluid-sizing('margin-bottom', $margin-bottom);
        line-height: 1.25;
    }

    .heading {
        text-align: center;
    }

    h2,h3,h4,h5,h6 {
        margin-top: 55px;
        $font-size: (320px: 18px, 1200px: 20px);
        @include poly-fluid-sizing('font-size', $font-size);
    }

    p {
        $font-size: (320px: 16px, 1200px: 18px);
        @include poly-fluid-sizing('font-size', $font-size);
        color: $blue;

        &.intro-content {
            font-weight: $semi;
            $font-size: (320px: 18px, 1200px: 20px);
            @include poly-fluid-sizing('font-size', $font-size);
            color: $primary;
        }
    }
}
