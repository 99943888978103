.footer {
    overflow: hidden;

    &:before {
        bottom: 0;
        background-color: $blue;
        opacity: .65;
    }

    .social-row {
        background: $primary;
    }

    .social-col {
        text-align: right;
        padding-top: 20px;

        a {
            padding: 10px;
            margin-left: 12px;
            border-radius: 50%;
            border: 1px solid $white;
            transition: all .3s ease;

            svg {
                transition: all .3s ease;
            }
        }

        .section-skew {
            z-index: 0;
        }
    }

    .coordinates-col {
        $padding-top: (320px: 55px, 1200px: 95px);
        $padding-bottom: (320px: 55px, 1200px: 95px);
        @include poly-fluid-sizing('padding-top', $padding-top);
        @include poly-fluid-sizing('padding-bottom', $padding-bottom);
        display: flex;
        justify-content: center;

        li {
            margin: 0 12px;
            text-align: center;
        }

        img {
            width: 38%;
        }

        .location {
            margin: 20px 0 0;
            color: $white;
            $font-size: (320px: 14px, 1200px: 20px);
            @include poly-fluid-sizing('font-size', $font-size);
        }
    }

    .legal-col {
        padding: 20px 0;

        p {

            &:not(.copyright) {
                margin-bottom: 12px;
                $font-size: (320px: 9px, 1200px: 12px);
                @include poly-fluid-sizing('font-size', $font-size);
                color: $primary;
            }

            a {
                color: $white;
            }
        }

        .copyright {
            color: $white;
            $font-size: (320px: 9px, 1200px: 11px);
            @include poly-fluid-sizing('font-size', $font-size);
        }
    }

    .ftr-hr-end {
        background: #14283a;
        $height: (320px: 240px, 768px: 220px, 992px: 225px, 1200px: 270px, 3000px: 290px);
        $bottom: (320px: -150px, 768px: -170px, 992px: -200px, 1200px: -220px, 3000px: -345px);
        @include poly-fluid-sizing('height', $height);
        @include poly-fluid-sizing('bottom', $bottom);
        z-index: 1;
    }
}
