@include media-breakpoint-up(xl) {

    /*
    |----------------------------------------------------------------
    | Header
    |----------------------------------------------------------------
    */
    header {

        .home-link {
            position: absolute;
            top: 80px;

            img {
                width: 260px;
            }
        }
    }

    /*
    |----------------------------------------------------------------
    | Navigation
    |-----------------------------------------------------------------
    */

    .gn--trigger {
        display: none;
    }

    .gn-wrap,
    .gn {
        display: block;
        position: static;
        width: 100%;
    }

    .gn {
        overflow: visible !important;
        padding: 0;
        background: transparent;
        display: flex;
        justify-content: flex-end;
        position: relative;
        $top: (1200px: 20px, 3000: 45px);
        @include poly-fluid-sizing('top', $top);

        .sub-menu {

            .menu-item {
                padding-left: 0;
                margin: 0;
                border-bottom: 1px solid $blue;

                &:first-child {
                    padding: 0;
                }

                &:last-child {
                    border: none;
                    padding: 0;
                }
            }
        }
    }

    .gn--items {
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: flex-end;

        .sub-trigger-wrap {
            display: none;
        }

        .menu-item {
            opacity: 1;
            font-size: 14px;
            margin: 0;
            position: relative;
            text-transform: uppercase;

            a {
                color: $blue;
                padding: 10px 25px;
            }

            &:hover {
                background: $primary;

                > a {
                    color: $white !important;
                }

                .sub-menu {
                    transition: .35s;
                    transform: translateY(0);
                    visibility: visible;
                    opacity: 1;

                    a {
                        padding: 20px;
                    }
                }
            }
        }

        .sub-menu {
            position: absolute;
            display: block !important;
            z-index: 10;
            width: auto;
            top: 35px;
            left: 0;
            min-width: 145%;
            transform: translateY(50%);
            transition-property: transform,opacity;
            visibility: hidden;
            opacity: 0;
            background: $primary;
            margin: 0;
            border: none;
        }
    }

    .mobile-search {
        opacity: 0;
        position: absolute;
        right: -20px;
        top: 55px;
        transition: all .38s ease;
        visibility: hidden;

        &.is-showing {
            visibility: visible;
            opacity: 1;
            top: 25px;
        }
    }

    .icon-holder {
        position: relative;
        width: 40px;
        height: 25px;

        &:focus {
            outline: 0;
        }
    }

    .ico-mglass {
        position:relative;
        display:inline-block;
        border-radius: 30px;
        height: 18px;
        width: 18px;
        border: 1px solid $white;
        margin-top: 5px;


      &:after {
        content: "";
        height: 1px;
        width: 8px;
        background: $white;
        position: absolute;
        top: 17px;
        left: 13px;
        transform: rotate(45deg);
      }
    }

    /*
    |----------------------------------------------------------------
    | Template: Homepage
    |----------------------------------------------------------------
    */
    .page-banner {

        .banner-content {

            .last-updated {
                text-align: right;
            }
        }
    }

    /*
    |----------------------------------------------------------------
    | Template: Pilot
    |----------------------------------------------------------------
    */

    .downloads {

        a {

            &.blue {

                &:hover {
                    background: $primary;
                }
            }

            &.turquoise {

                &:hover {
                    background: $blue;
                }
            }
        }
    }

    /*
    |----------------------------------------------------------------
    | Component: Gallery
    |----------------------------------------------------------------
    */
    .gallery {

        a {

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                background: $blue;
                opacity: 0;
                transition: all .3s ease;
            }

            &:hover {

                &:before {
                    opacity: .65;
                }
            }
        }
    }

    /*
    |----------------------------------------------------------------
    | Footer
    |----------------------------------------------------------------
    */
    .footer {

        .social-col {

            .icon {

                &:hover {
                    border-color: $blue;

                    svg {
                        fill: $blue !important;
                    }
                }
            }
        }

        .social-col hr,
        .ftr-hr-end {
            transform: skewY(6.5deg) translateY(-50%) translateZ(0);
        }
    }

}
