@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600");
:root {
  --blue: #14283a;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #508391;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #508391;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #355660;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #508391 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #3e6570 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #508391 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #508391 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #355660 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*

  An example
  h1 {
    $map: (576px: 22px, 768px: 24px, 992px: 34px);
    @include poly-fluid-sizing('font-size', $map);
    color: #e95c33;
  }

*/
html {
  line-height: 1.3;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0; }

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  min-width: 320px;
  color: #14283a;
  direction: ltr;
  text-align: left;
  background-color: #fff; }

main {
  max-width: 1400px;
  margin: 0 auto; }

.theme-wrap {
  width: 100%;
  overflow-x: hidden !important;
  transition: opacity .26s ease; }
  .theme-wrap.show-theme {
    opacity: 1 !important; }

section {
  padding-top: 65px;
  padding-bottom: 65px; }
  @media (min-width: 320px) {
    section {
      padding-top: calc(6.8181818182vw + 43.1818181818px); } }
  @media (min-width: 1200px) {
    section {
      padding-top: 125px; } }
  @media (min-width: 320px) {
    section {
      padding-bottom: calc(6.8181818182vw + 43.1818181818px); } }
  @media (min-width: 1200px) {
    section {
      padding-bottom: 125px; } }
  section.gallery {
    margin-bottom: 65px; }
    @media (min-width: 320px) {
      section.gallery {
        margin-bottom: calc(6.8181818182vw + 43.1818181818px); } }
    @media (min-width: 1200px) {
      section.gallery {
        margin-bottom: 125px; } }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

.background-center {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.is-on-top {
  position: relative;
  z-index: 2; }

.row-center {
  justify-content: center; }

.full-screen {
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; }

/*
|----------------------------------------------------------------
|  Padding and Margins
|----------------------------------------------------------------
*/
.no-padding {
  padding: 0; }

.no-padding-top {
  padding-top: 0; }

.no-padding-bottom {
  padding-bottom: 0; }

.no-margin {
  margin: 0; }

/*
|----------------------------------------------------------------
|  Positioning
|----------------------------------------------------------------
*/
.is-relative {
  position: relative; }

.is-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); }

.has-absolute-before {
  position: relative; }
  .has-absolute-before:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }

.has-absolute-after {
  position: relative; }
  .has-absolute-after:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }

/*
|----------------------------------------------------------------
|  Trigger icons for global nav and mobile pilots template
|----------------------------------------------------------------
*/
.subnav-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 20px;
  position: absolute;
  right: 0;
  top: 16.5px;
  cursor: pointer; }
  .subnav-icon:before, .subnav-icon:after {
    content: "";
    display: block;
    background: #508391;
    transition: all 0.3s ease;
    position: absolute; }
  .subnav-icon:before {
    width: 100%;
    height: 3px;
    top: 50%;
    opacity: 1;
    margin-top: -1.5px; }
  .subnav-icon:after {
    width: 3px;
    height: 100%;
    left: 50%;
    margin-left: -1.5px; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, main, section {
  display: block; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

blockquote {
  margin: 0 0 1rem; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  border: none;
  background: transparent;
  outline: 0; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.is-uppercase {
  text-transform: uppercase; }

b,
strong {
  font-weight: 600;
  color: #14283a; }

.text-center {
  text-align: center; }

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 20px 0;
  font-weight: 600;
  color: #508391; }

h1, h2,
.h2 {
  font-size: 35px; }
  @media (min-width: 320px) {
    h1, h2,
    .h2 {
      font-size: calc(1.7045454545vw + 29.5454545455px); } }
  @media (min-width: 1200px) {
    h1, h2,
    .h2 {
      font-size: 50px; } }

.main-heading {
  text-transform: uppercase; }
  .main-heading br {
    display: none; }

/*
|----------------------------------------------------------------
|  Color
|----------------------------------------------------------------
*/
.is-blue {
  color: #14283a; }

/*
|----------------------------------------------------------------
|  Paragraph
|----------------------------------------------------------------
*/
p {
  margin: 0;
  margin-bottom: 25px;
  line-height: 1.5;
  font-size: 16px; }
  @media (min-width: 320px) {
    p {
      font-size: calc(0.2272727273vw + 15.2727272727px); } }
  @media (min-width: 1200px) {
    p {
      font-size: 18px; } }
  p.intro-content {
    font-weight: 600;
    color: #507d89; }
  p:last-of-type {
    margin-bottom: 0; }
  p:empty {
    display: none; }
  p a {
    color: #14283a;
    border-bottom: 2px solid #507d89; }

/*
|----------------------------------------------------------------
|  Links
|----------------------------------------------------------------
*/
a {
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
  transition: all 275ms ease; }
  a:hover, a:focus, a:active {
    text-decoration: none;
    outline: 0 !important; }

.read-more {
  margin-top: 12px;
  font-weight: 700;
  display: inline-flex; }

/*
|----------------------------------------------------------------
|  Lists
|----------------------------------------------------------------
*/
ol,
ul,
dl {
  padding: 0;
  margin: 0;
  list-style: none; }

li {
  margin-bottom: 25px; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

.custom-bullet-lists ul {
  padding-left: 25px;
  margin-bottom: 25px; }
  .custom-bullet-lists ul li {
    position: relative; }
    .custom-bullet-lists ul li:before {
      content: '•';
      font-size: 18px;
      color: #2684fe;
      position: absolute;
      top: -1px;
      left: -15px; }

.custom-bullet-lists ol {
  padding-left: 25px;
  margin-bottom: 25px;
  counter-reset: my-counter; }
  .custom-bullet-lists ol li:before {
    content: counter(my-counter) ".";
    counter-increment: my-counter;
    top: 1px;
    left: -5px;
    position: relative;
    color: #2684fe; }

.button {
  font-size: 15px;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-shadow: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 22px;
  border-radius: 5px;
  border: 1px solid transparent;
  background: #508391;
  color: #f0f0f0;
  font-weight: 600; }

/*
|----------------------------------------------------------------
| Heading
|----------------------------------------------------------------
*/
.scg-heading-col .icon-wrap {
  flex-basis: 50px;
  max-width: 50px;
  height: 50px;
  padding: 13px;
  background: #508391;
  margin-right: 15px; }

.scg-heading-col .heading {
  flex: 1;
  color: #14283a;
  font-size: 18px;
  margin: 0;
  text-transform: capitalize; }
  @media (min-width: 320px) {
    .scg-heading-col .heading {
      font-size: calc(0.2272727273vw + 17.2727272727px); } }
  @media (min-width: 1200px) {
    .scg-heading-col .heading {
      font-size: 20px; } }

/*
|----------------------------------------------------------------
| Table
|----------------------------------------------------------------
*/
.scg-list {
  margin: 30px 0 0 25px; }
  .scg-list li {
    border-bottom: 1px solid #a1a1a1;
    color: #14283a;
    font-size: 16px; }
    @media (min-width: 320px) {
      .scg-list li {
        font-size: calc(0.2272727273vw + 15.2727272727px); } }
    @media (min-width: 1200px) {
      .scg-list li {
        font-size: 18px; } }
    .scg-list li.charter-item {
      font-weight: 600; }
    .scg-list li .heading {
      font-weight: 600;
      color: #14283a; }
    .scg-list li .charter-heading {
      display: inline-block; }
    .scg-list li .website a {
      color: #14283a; }
    .scg-list li span,
    .scg-list li p {
      display: block;
      color: #508391; }
  .scg-list:not(:first-of-type) {
    margin-top: 55px; }
    @media (min-width: 320px) {
      .scg-list:not(:first-of-type) {
        margin-top: calc(1.7045454545vw + 49.5454545455px); } }
    @media (min-width: 1200px) {
      .scg-list:not(:first-of-type) {
        margin-top: 70px; } }

.gn--trigger {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 0;
  right: 0;
  padding: 0;
  outline: none;
  border: none;
  transition: all 0.37s ease;
  z-index: 99; }
  .gn--trigger:focus {
    outline: 0; }
  .gn--trigger .burger {
    width: 25px;
    height: 2px;
    background: #fff;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    transition: all 0.37s ease-in-out; }
    .gn--trigger .burger:first-child {
      top: 20px; }
    .gn--trigger .burger:last-child {
      top: auto;
      bottom: 18px; }
  .gn--trigger.burger-active .burger {
    width: 35px; }
    .gn--trigger.burger-active .burger:first-child {
      transform: rotate(-135deg);
      top: 30px;
      left: 12px; }
    .gn--trigger.burger-active .burger:nth-child(2) {
      opacity: 0;
      left: -25px; }
    .gn--trigger.burger-active .burger:last-child {
      transform: rotate(135deg);
      left: 12px;
      top: 30px; }

/*
 |----------------------------------------------------------------
 |  Global Navigation
 |----------------------------------------------------------------
 */
.gn-wrap {
  background: #508391;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  transition: width 300ms cubic-bezier(0.55, 0.21, 0, 0.93) 350ms; }
  .gn-wrap.expand-wrap {
    min-height: 100vh;
    width: 100vw;
    transition: width 300ms cubic-bezier(0.55, 0.21, 0, 0.93) 375ms; }

.gn {
  width: 0;
  position: fixed;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch;
  top: 60px;
  left: 0;
  bottom: 0;
  z-index: 10;
  transition: width 400ms cubic-bezier(0.55, 0.21, 0, 0.93) 425ms;
  background: #fff; }
  .gn.gn--open {
    width: 100vw; }
  .gn .sub-menu {
    display: none;
    padding: 0;
    width: 100vw;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    margin: 8px 0 12px; }
    .gn .sub-menu .menu-item {
      font-size: 14px;
      opacity: 1;
      padding-left: 25px;
      margin-bottom: 15px; }
      .gn .sub-menu .menu-item:first-child {
        padding-top: 10px; }
      .gn .sub-menu .menu-item:last-child {
        padding-bottom: 10px; }

.gn--items {
  padding: 12px 0 0; }
  .gn--items .menu-item {
    font-weight: 500;
    font-size: 18px;
    position: relative;
    opacity: 0;
    transition: opacity .35s ease;
    line-height: 1.2;
    font-weight: 600;
    transition: all .3s ease;
    margin-bottom: 5px; }
    @media (min-width: 320px) {
      .gn--items .menu-item {
        font-size: calc(0.4464285714vw + 16.5714285714px); } }
    @media (min-width: 768px) {
      .gn--items .menu-item {
        font-size: calc(0vw + 20px); } }
    @media (min-width: 992px) {
      .gn--items .menu-item {
        font-size: calc(1.2987012987vw + 7.1168831169px); } }
    @media (min-width: 1300px) {
      .gn--items .menu-item {
        font-size: 24px; } }
    .gn--items .menu-item.active {
      background: #508391; }
      .gn--items .menu-item.active > a {
        color: #fff !important; }
      .gn--items .menu-item.active .subnav-icon:after,
      .gn--items .menu-item.active .subnav-icon:before {
        background: #fff; }
      .gn--items .menu-item.active .sub-menu {
        background: #fff; }
    .gn--items .menu-item.menu-item-has-children > a {
      cursor: default !important; }
    .gn--items .menu-item a {
      display: block;
      color: #14283a;
      transition: all 250ms ease;
      padding: 15px 25px;
      cursor: pointer; }
    .gn--items .menu-item.slide-down-sub-nav .subnav-icon:after {
      transform: rotate(90deg); }
    .gn--items .menu-item.slide-down-sub-nav .subnav-icon:before {
      transform: rotate(90deg);
      opacity: 0; }
    .gn--items .menu-item.menu-item-has-children > a {
      position: relative; }
    .gn--items .menu-item.show-item {
      opacity: 1;
      top: 0;
      transition: opacity .35s ease 700ms; }

.mobile-search {
  opacity: 0;
  transition: opacity .35s ease;
  margin: 0 25px; }
  .mobile-search.show-item {
    opacity: 1;
    transition: opacity .35s ease 700ms; }
  .mobile-search .search-bar {
    width: 100%;
    border: 1px solid #508391;
    padding: 12px; }

.slbOverlay, .slbWrapOuter, .slbWrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.slbOverlay {
  overflow: hidden;
  z-index: 2000;
  background-color: #000;
  opacity: 0.8;
  animation: slbOverlay 0.5s; }

.slbWrapOuter {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2010; }

.slbWrap {
  position: absolute;
  text-align: center; }

.slbWrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.slbContentOuter {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0px auto;
  padding: 0 1em;
  box-sizing: border-box;
  z-index: 2020;
  text-align: left;
  max-width: 100%; }

.slbContentEl .slbContentOuter {
  padding: 5em 1em; }

.slbContent {
  position: relative; }

.slbContentEl .slbContent {
  animation: slbEnter 0.3s;
  background-color: #fff;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.4); }

.slbImageWrap {
  animation: slbEnter 0.3s;
  position: relative; }

.slbImageWrap:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 5em;
  bottom: 5em;
  display: block;
  z-index: -1;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.6);
  background-color: #FFF; }

.slbDirectionNext .slbImageWrap {
  animation: slbEnterNext 0.4s; }

.slbDirectionPrev .slbImageWrap {
  animation: slbEnterPrev 0.4s; }

.slbImage {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 5em 0;
  margin: 0 auto; }

.slbCaption {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  font-size: 1.4em;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.71429em 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
  text-align: center; }

.slbCloseBtn, .slbArrow {
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none; }

.slbCloseBtn::-moz-focus-inner, .slbArrow::-moz-focus-inner {
  padding: 0;
  border: 0; }

.slbCloseBtn:hover, .slbArrow:hover {
  opacity: 0.5; }

.slbCloseBtn:active, .slbArrow:active {
  opacity: 0.8; }

.slbCloseBtn {
  animation: slbEnter 0.3s;
  font-size: 3em;
  width: 1.66667em;
  height: 1.66667em;
  line-height: 1.66667em;
  position: absolute;
  right: -0.33333em;
  top: 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
  text-align: center; }

.slbLoading .slbCloseBtn {
  display: none; }

.slbLoadingText {
  font-size: 1.4em;
  color: #fff;
  color: rgba(255, 255, 255, 0.9); }

.slbArrows {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0; }

.slbLoading .slbArrows {
  display: none; }

.slbArrow {
  position: absolute;
  top: 50%;
  margin-top: -5em;
  width: 5em;
  height: 10em;
  opacity: 0.7;
  text-indent: -999em;
  overflow: hidden; }

.slbArrow:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.8em 0 0 -0.8em;
  border: 0.8em solid transparent; }

.slbArrow.next {
  right: 0; }

.slbArrow.next:before {
  border-left-color: #fff; }

.slbArrow.prev {
  left: 0; }

.slbArrow.prev:before {
  border-right-color: #fff; }

.slbIframeCont {
  width: 80em;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  margin: 5em 0; }

.slbIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.6);
  background: #000; }

@keyframes slbOverlay {
  from {
    opacity: 0; }
  to {
    opacity: 0.7; } }

@keyframes slbEnter {
  from {
    opacity: 0;
    transform: translate3d(0, -1em, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes slbEnterNext {
  from {
    opacity: 0;
    transform: translate3d(4em, 0, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes slbEnterPrev {
  from {
    opacity: 0;
    transform: translate3d(-4em, 0, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c1c1c1; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #c1c1c1; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #c1c1c1; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #c1c1c1; }

.gform_validation_container {
  display: none !important; }

[type="text"],
[type="url"],
[type="email"],
[type="tel"],
[type="number"],
[type="password"],
[type="search"],
select,
textarea {
  transition-duration: .28s;
  transition-property: background-color, color, border, opacity, transform;
  box-shadow: none;
  border-radius: 5px;
  outline: none;
  border: 1px solid #c1c1c1;
  background: white;
  padding: 8px 10px;
  width: 100%;
  appearance: none; }
  [type="text"]:focus,
  [type="url"]:focus,
  [type="email"]:focus,
  [type="tel"]:focus,
  [type="number"]:focus,
  [type="password"]:focus,
  [type="search"]:focus,
  select:focus,
  textarea:focus {
    outline: none;
    background: #f0f0f0; }

select,
textarea {
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 10px 12px;
  color: #14283a; }

textarea {
  height: 200px; }

.gfield:not(.show-label) .gfield_label {
  display: none; }

.gfield .gfield_required {
  position: relative;
  top: 9px;
  right: -2px;
  line-height: 1;
  font-size: 22px; }

.validation_error {
  background: red;
  color: #fff;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 10px; }

.validation_message {
  color: red;
  text-align: left;
  font-weight: 500;
  margin-top: 5px; }

/*
|----------------------------------------------------------------
|  IATS Form
|----------------------------------------------------------------
*/
#IATS_PaymentBoxDiv {
  width: 100% !important; }

.IATS_PaymentSectionTitle,
.IATS_AmountSectionTitle {
  color: #508391 !important; }

#IATS_ProcessAction_Button {
  font-size: 15px;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-shadow: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 22px;
  border-radius: 5px;
  border: 1px solid transparent;
  background: #508391;
  color: #f0f0f0;
  font-weight: 600; }

/*
 |----------------------------------------------------------------
 |  Skew Base
 |----------------------------------------------------------------
 */
.section-skew {
  background-clip: content-box;
  backface-visibility: hidden;
  padding-bottom: .0625rem;
  padding-top: .0625rem;
  z-index: 1;
  border: none;
  display: block;
  height: 9vw;
  left: 0;
  margin: 0;
  max-width: none;
  position: absolute;
  right: 0;
  background: #508391;
  top: -1vw;
  transform: skewY(3.6deg) translateY(-50%) translateZ(0);
  width: 100vw;
  outline: 1px solid transparent;
  will-change: transform;
  -webkit-perspective: 1000; }
  .section-skew.reversed {
    transform: skewY(-3.6deg) translateY(-50%); }
  .section-skew.keep-to-bottom {
    bottom: -8vw;
    top: auto; }

/*! Flickity v2.2.0
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  tap-highlight-color: transparent;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: 20px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: transparent;
  border: 1px solid #fff;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1;
  background: #fff; }

.gallery {
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0; }
  .gallery .image-slider {
    width: 100%; }
    .gallery .image-slider .img {
      width: 100%;
      padding-bottom: 100%;
      margin: 0;
      position: relative; }
    .gallery .image-slider:after {
      content: 'flickity';
      display: none; }

.main {
  position: relative;
  width: 100%; }

.page-banner {
  overflow: hidden;
  padding: 0; }
  .page-banner:before {
    bottom: 0;
    background-color: #14283a;
    opacity: .65; }
  .page-banner .nav-col {
    z-index: 3; }
  .page-banner .banner-content {
    height: 300px; }
    @media (min-width: 320px) {
      .page-banner .banner-content {
        height: calc(33.7078651685vw + 192.134831461px); } }
    @media (min-width: 765px) {
      .page-banner .banner-content {
        height: calc(80.4597701149vw - 165.517241379px); } }
    @media (min-width: 1200px) {
      .page-banner .banner-content {
        height: 800px; } }
  .page-banner.home-banner .banner-content {
    padding-top: 45px;
    padding-bottom: 55px;
    height: auto; }
    @media (min-width: 320px) {
      .page-banner.home-banner .banner-content {
        padding-top: calc(5.6818181818vw + 26.8181818182px); } }
    @media (min-width: 1200px) {
      .page-banner.home-banner .banner-content {
        padding-top: calc(13.75vw - 70px); } }
    @media (min-width: 1600px) {
      .page-banner.home-banner .banner-content {
        padding-top: calc(12.5vw - 50px); } }
    @media (min-width: 2000px) {
      .page-banner.home-banner .banner-content {
        padding-top: 200px; } }
    @media (min-width: 320px) {
      .page-banner.home-banner .banner-content {
        padding-bottom: calc(5.6818181818vw + 36.8181818182px); } }
    @media (min-width: 1200px) {
      .page-banner.home-banner .banner-content {
        padding-bottom: calc(11.25vw - 30px); } }
    @media (min-width: 1600px) {
      .page-banner.home-banner .banner-content {
        padding-bottom: calc(12.5vw - 50px); } }
    @media (min-width: 2000px) {
      .page-banner.home-banner .banner-content {
        padding-bottom: 200px; } }
    .page-banner.home-banner .banner-content svg path + path {
      fill: #508391; }
    .page-banner.home-banner .banner-content .last-updated {
      color: #fff;
      font-size: 10px; }
  .page-banner .gauge-3, .page-banner .gauge-4 {
    margin-top: 40px; }

header {
  position: relative;
  background: #508391; }

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto; }

.aligncenter {
  display: block;
  margin: 0.5rem auto;
  height: auto; }

.alignleft,
.alignright {
  margin-bottom: 0.5rem;
  height: auto; }

@media (min-width: 576px) {
  .alignleft {
    float: left;
    margin-right: 0.5rem; }
  .alignright {
    float: right;
    margin-left: 0.5rem; } }

header .home-link {
  width: 156px;
  position: relative;
  left: -20px;
  z-index: 20; }

.footer {
  overflow: hidden; }
  .footer:before {
    bottom: 0;
    background-color: #14283a;
    opacity: .65; }
  .footer .social-row {
    background: #508391; }
  .footer .social-col {
    text-align: right;
    padding-top: 20px; }
    .footer .social-col a {
      padding: 10px;
      margin-left: 12px;
      border-radius: 50%;
      border: 1px solid #fff;
      transition: all .3s ease; }
      .footer .social-col a svg {
        transition: all .3s ease; }
    .footer .social-col .section-skew {
      z-index: 0; }
  .footer .coordinates-col {
    padding-top: 55px;
    padding-bottom: 55px;
    display: flex;
    justify-content: center; }
    @media (min-width: 320px) {
      .footer .coordinates-col {
        padding-top: calc(4.5454545455vw + 40.4545454545px); } }
    @media (min-width: 1200px) {
      .footer .coordinates-col {
        padding-top: 95px; } }
    @media (min-width: 320px) {
      .footer .coordinates-col {
        padding-bottom: calc(4.5454545455vw + 40.4545454545px); } }
    @media (min-width: 1200px) {
      .footer .coordinates-col {
        padding-bottom: 95px; } }
    .footer .coordinates-col li {
      margin: 0 12px;
      text-align: center; }
    .footer .coordinates-col img {
      width: 38%; }
    .footer .coordinates-col .location {
      margin: 20px 0 0;
      color: #fff;
      font-size: 14px; }
      @media (min-width: 320px) {
        .footer .coordinates-col .location {
          font-size: calc(0.6818181818vw + 11.8181818182px); } }
      @media (min-width: 1200px) {
        .footer .coordinates-col .location {
          font-size: 20px; } }
  .footer .legal-col {
    padding: 20px 0; }
    .footer .legal-col p:not(.copyright) {
      margin-bottom: 12px;
      font-size: 9px;
      color: #508391; }
      @media (min-width: 320px) {
        .footer .legal-col p:not(.copyright) {
          font-size: calc(0.3409090909vw + 7.9090909091px); } }
      @media (min-width: 1200px) {
        .footer .legal-col p:not(.copyright) {
          font-size: 12px; } }
    .footer .legal-col p a {
      color: #fff; }
    .footer .legal-col .copyright {
      color: #fff;
      font-size: 9px; }
      @media (min-width: 320px) {
        .footer .legal-col .copyright {
          font-size: calc(0.2272727273vw + 8.2727272727px); } }
      @media (min-width: 1200px) {
        .footer .legal-col .copyright {
          font-size: 11px; } }
  .footer .ftr-hr-end {
    background: #14283a;
    height: 240px;
    bottom: -150px;
    z-index: 1; }
    @media (min-width: 320px) {
      .footer .ftr-hr-end {
        height: calc(-4.4642857143vw + 254.285714286px); } }
    @media (min-width: 768px) {
      .footer .ftr-hr-end {
        height: calc(2.2321428571vw + 202.857142857px); } }
    @media (min-width: 992px) {
      .footer .ftr-hr-end {
        height: calc(21.6346153846vw + 10.3846153846px); } }
    @media (min-width: 1200px) {
      .footer .ftr-hr-end {
        height: calc(1.1111111111vw + 256.666666667px); } }
    @media (min-width: 3000px) {
      .footer .ftr-hr-end {
        height: 290px; } }
    @media (min-width: 320px) {
      .footer .ftr-hr-end {
        bottom: calc(-4.4642857143vw - 135.714285714px); } }
    @media (min-width: 768px) {
      .footer .ftr-hr-end {
        bottom: calc(-13.3928571429vw - 67.1428571429px); } }
    @media (min-width: 992px) {
      .footer .ftr-hr-end {
        bottom: calc(-9.6153846154vw - 104.615384615px); } }
    @media (min-width: 1200px) {
      .footer .ftr-hr-end {
        bottom: calc(-6.9444444444vw - 136.666666667px); } }
    @media (min-width: 3000px) {
      .footer .ftr-hr-end {
        bottom: -345px; } }

body#tinymce {
  margin: 12px !important; }

.page-is-404 .button {
  margin-top: 40px;
  text-transform: uppercase; }

/*
|----------------------------------------------------------------
|  Pilots Intro
|----------------------------------------------------------------
*/
.pilots-intro p {
  font-weight: 600;
  font-size: 18px; }
  @media (min-width: 320px) {
    .pilots-intro p {
      font-size: calc(0.6818181818vw + 15.8181818182px); } }
  @media (min-width: 1200px) {
    .pilots-intro p {
      font-size: 24px; } }

/*
|----------------------------------------------------------------
|  Flexible Content
|----------------------------------------------------------------
*/
.pilot-details .layout-row {
  margin-bottom: 30px; }
  .pilot-details .layout-row:last-of-type {
    margin: 0; }

.pilot-details .content {
  display: none;
  background: #ebebeb;
  padding: 15px 12px; }

.pilot-details .scg-heading-col .subnav-icon {
  margin: 0; }
  .pilot-details .scg-heading-col .subnav-icon.is-active:after {
    transform: rotate(90deg); }
  .pilot-details .scg-heading-col .subnav-icon.is-active:before {
    transform: rotate(90deg);
    opacity: 0; }

.pilot-details .heading {
  font-size: 13px;
  padding-right: 25px; }
  @media (min-width: 320px) {
    .pilot-details .heading {
      font-size: calc(1.6666666667vw + 7.6666666667px); } }
  @media (min-width: 560px) {
    .pilot-details .heading {
      font-size: calc(0.46875vw + 14.375px); } }
  @media (min-width: 1200px) {
    .pilot-details .heading {
      font-size: 20px; } }

.pilot-details .scg-list {
  margin: 0; }
  .pilot-details .scg-list li {
    margin-bottom: 12px; }

.table-body li {
  border-bottom: 1px solid #a1a1a1; }

.table-body .heading {
  font-weight: 600;
  font-size: 14px;
  color: #14283a;
  padding-right: 3px; }

/*
|----------------------------------------------------------------
|  Downloads
|----------------------------------------------------------------
*/
.downloads {
  margin-top: 55px; }
  @media (min-width: 768px) {
    .downloads {
      margin-top: calc(6.9444444444vw + 1.6666666667px); } }
  @media (min-width: 1200px) {
    .downloads {
      margin-top: 85px; } }
  .downloads li {
    font-size: 18px;
    font-weight: 600;
    text-align: center; }
    @media (min-width: 320px) {
      .downloads li {
        font-size: calc(0.2272727273vw + 17.2727272727px); } }
    @media (min-width: 1200px) {
      .downloads li {
        font-size: 20px; } }
  .downloads a {
    color: #fff;
    padding: 12px 30px;
    margin-bottom: 30px; }
    .downloads a.turquoise {
      background: #508391; }
    .downloads a.blue {
      background: #14283a; }

.search-pg .search-terms {
  font-size: 18px; }
  .search-pg .search-terms span {
    border-bottom: 1px solid #508391; }

.search-results {
  margin-top: 60px; }
  .search-results article {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ebebeb; }
  .search-results .entry-image {
    height: 150px;
    margin-bottom: 30px; }
    @media (min-width: 320px) {
      .search-results .entry-image {
        height: calc(15.625vw + 100px); } }
    @media (min-width: 768px) {
      .search-results .entry-image {
        height: 220px; } }
  .search-results .entry-title {
    font-size: 22px; }
    @media (min-width: 320px) {
      .search-results .entry-title {
        font-size: calc(0.7954545455vw + 19.4545454545px); } }
    @media (min-width: 1200px) {
      .search-results .entry-title {
        font-size: 29px; } }
  .search-results .learn-more {
    font-size: 15px;
    color: #14283a;
    font-weight: 600;
    padding-top: 25px;
    display: inline-block; }
    @media (min-width: 320px) {
      .search-results .learn-more {
        font-size: calc(0.3409090909vw + 13.9090909091px); } }
    @media (min-width: 1200px) {
      .search-results .learn-more {
        font-size: 18px; } }

.table-title {
  background: #50838E;
  color: #fff;
  display: inline-block;
  width: auto;
  padding: 8px 12px;
  margin-bottom: 0; }

.table-wrap h2, .table-wrap h3, .table-wrap h4, .table-wrap h5, .table-wrap h6 {
  margin: 0 0 4px !important;
  color: #14283a; }

.table-wrap .tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }
  .table-wrap .tr:nth-child(even) {
    background: #ebebeb; }

.table-wrap .th {
  background: #50838E;
  font-weight: 600; }
  .table-wrap .th p {
    color: #fff;
    font-size: 12px; }

.table-wrap .cell {
  padding: 12px; }
  .table-wrap .cell:nth-child(4n + 2) {
    display: none; }
  .table-wrap .cell p {
    font-size: 14px; }

/*
|----------------------------------------------------------------
|  Content
|----------------------------------------------------------------
*/
.hp-content .content-col p {
  font-weight: 600;
  font-size: 18px; }
  @media (min-width: 320px) {
    .hp-content .content-col p {
      font-size: calc(0.6818181818vw + 15.8181818182px); } }
  @media (min-width: 1200px) {
    .hp-content .content-col p {
      font-size: 24px; } }

/*
|----------------------------------------------------------------
|  Fees
|----------------------------------------------------------------
*/
.fees-blk .box-1 .img-wrap,
.fees-blk .box-1 .fee-wrap {
  background: #14283a; }

.fees-blk .box-2 {
  margin-top: 45px; }
  .fees-blk .box-2 .img-wrap,
  .fees-blk .box-2 .fee-wrap {
    background: #508391; }

.fees-blk .img-wrap {
  width: 50px;
  height: 50px;
  padding: 12px; }

.fees-blk .fee-wrap {
  text-align: center;
  padding: 30px 15px; }
  .fees-blk .fee-wrap * {
    color: #fff; }

.fees-blk .fee-heading {
  margin-top: 0;
  font-size: 17px; }
  @media (min-width: 320px) {
    .fees-blk .fee-heading {
      font-size: calc(0.3409090909vw + 15.9090909091px); } }
  @media (min-width: 1200px) {
    .fees-blk .fee-heading {
      font-size: 20px; } }

.fees-blk .fee {
  font-size: 20px;
  font-weight: 600; }
  @media (min-width: 320px) {
    .fees-blk .fee {
      font-size: calc(1.7045454545vw + 14.5454545455px); } }
  @media (min-width: 1200px) {
    .fees-blk .fee {
      font-size: 35px; } }

.fees-blk li {
  margin: 0;
  padding: 15px 0;
  text-align: center;
  color: #14283a; }
  .fees-blk li:nth-child(even) {
    background: #ebebeb; }

/*
|----------------------------------------------------------------
|  General Information
|----------------------------------------------------------------
*/
.general-info {
  margin-bottom: 55px; }
  @media (min-width: 320px) {
    .general-info {
      margin-bottom: calc(1.7045454545vw + 49.5454545455px); } }
  @media (min-width: 1200px) {
    .general-info {
      margin-bottom: 70px; } }

/*
|----------------------------------------------------------------
|  Label
|----------------------------------------------------------------
*/
.default-template .main-heading {
  margin-bottom: 30px;
  line-height: 1.25; }
  @media (min-width: 320px) {
    .default-template .main-heading {
      margin-bottom: calc(3.6363636364vw + 18.3636363636px); } }
  @media (min-width: 1200px) {
    .default-template .main-heading {
      margin-bottom: 62px; } }

.default-template .heading {
  text-align: center; }

.default-template h2, .default-template h3, .default-template h4, .default-template h5, .default-template h6 {
  margin-top: 55px;
  font-size: 18px; }
  @media (min-width: 320px) {
    .default-template h2, .default-template h3, .default-template h4, .default-template h5, .default-template h6 {
      font-size: calc(0.2272727273vw + 17.2727272727px); } }
  @media (min-width: 1200px) {
    .default-template h2, .default-template h3, .default-template h4, .default-template h5, .default-template h6 {
      font-size: 20px; } }

.default-template p {
  font-size: 16px;
  color: #14283a; }
  @media (min-width: 320px) {
    .default-template p {
      font-size: calc(0.2272727273vw + 15.2727272727px); } }
  @media (min-width: 1200px) {
    .default-template p {
      font-size: 18px; } }
  .default-template p.intro-content {
    font-weight: 600;
    font-size: 18px;
    color: #508391; }
    @media (min-width: 320px) {
      .default-template p.intro-content {
        font-size: calc(0.2272727273vw + 17.2727272727px); } }
    @media (min-width: 1200px) {
      .default-template p.intro-content {
        font-size: 20px; } }

/*
|----------------------------------------------------------------
|  Content
|----------------------------------------------------------------
*/
.contact .main-heading {
  margin-bottom: 30px; }
  @media (min-width: 320px) {
    .contact .main-heading {
      margin-bottom: calc(1.7045454545vw + 24.5454545455px); } }
  @media (min-width: 1200px) {
    .contact .main-heading {
      margin-bottom: 45px; } }

.contact p {
  font-weight: 600;
  font-size: 18px; }
  @media (min-width: 320px) {
    .contact p {
      font-size: calc(0.2272727273vw + 17.2727272727px); } }
  @media (min-width: 1200px) {
    .contact p {
      font-size: 20px; } }

/*
|----------------------------------------------------------------
|  Contact Details
|----------------------------------------------------------------
*/
.contact-details {
  position: relative;
  background: #14283a; }
  .contact-details li {
    font-size: 16px;
    margin-bottom: 50px; }
    @media (min-width: 320px) {
      .contact-details li {
        font-size: calc(0.2272727273vw + 15.2727272727px); } }
    @media (min-width: 1200px) {
      .contact-details li {
        font-size: 18px; } }
    .contact-details li:last-child {
      margin: 0; }
  .contact-details img {
    width: 40px;
    display: block;
    margin: 0 auto 18px; }
  .contact-details li,
  .contact-details a,
  .contact-details address {
    color: #fff; }

/*
|----------------------------------------------------------------
|  Map
|----------------------------------------------------------------
*/
.google-map {
  height: 300px; }
  @media (min-width: 320px) {
    .google-map {
      height: calc(28.4090909091vw + 209.090909091px); } }
  @media (min-width: 1200px) {
    .google-map {
      height: 550px; } }

/*
|----------------------------------------------------------------
|  Label
|----------------------------------------------------------------
*/
@media (min-width: 768px) {
  /*
    |----------------------------------------------------------------
    | Typography
    |----------------------------------------------------------------
    */
  .main-heading br {
    display: block; }
  /*
    |----------------------------------------------------------------
    | Template: Homepage
    |----------------------------------------------------------------
    */
  .page-banner .banner-content .last-updated {
    text-align: right; }
  /*
    |----------------------------------------------------------------
    | Template: Pilots
    |----------------------------------------------------------------
    */
  .pilot-details .layout-row {
    margin-bottom: 70px; }
  .pilot-details .content {
    display: block !important;
    background: transparent;
    padding: 0;
    margin: 15px 0 0 85px; }
    .pilot-details .content.cell-count-3 .common-cell {
      flex-basis: 33.3333333%;
      max-width: 33.3333333%; }
    .pilot-details .content.cell-count-4 .common-cell {
      flex-basis: 25%;
      max-width: 25%; }
    .pilot-details .content.cell-count-5 .common-cell {
      flex-basis: 20%;
      max-width: 20%; }
  .pilot-details .common-cell {
    padding-right: 20px; }
  .pilot-details .table-head {
    border-bottom: 1px solid #a1a1a1;
    margin-bottom: 25px; }
    .pilot-details .table-head .heading {
      color: #14283a;
      font-weight: 600;
      font-size: 14px; } }
    @media (min-width: 768px) and (min-width: 768px) {
      .pilot-details .table-head .heading {
        font-size: calc(0.9259259259vw + 6.8888888889px); } }
    @media (min-width: 768px) and (min-width: 1200px) {
      .pilot-details .table-head .heading {
        font-size: 18px; } }

@media (min-width: 768px) {
  .downloads li {
    margin: 0 12px; }
  /*
    |----------------------------------------------------------------
    | Template: Reporting and Payment
    |----------------------------------------------------------------
    */
  .table-wrap h3 {
    margin: 0 0 -4px !important; }
  .table-wrap .tr {
    grid-template-columns: 1fr 1fr 1fr 1fr; }
    .table-wrap .tr .cell:first-child p {
      font-size: 13px; }
  .table-wrap .cell p {
    font-size: 15px; }
  .table-wrap .cell.th p {
    font-size: 13px; }
  .table-wrap .cell:nth-child(4n + 2) {
    display: block; }
  /*
    |----------------------------------------------------------------
    | Template: Airport Services
    |----------------------------------------------------------------
    */
  .fees-blk .box-2 {
    margin-top: 0; }
  /*
    |----------------------------------------------------------------
    | Template: Contact
    |----------------------------------------------------------------
    */
  .contact-details ul {
    display: flex;
    justify-content: center; }
  .contact-details li {
    margin: 0;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  /*
    |----------------------------------------------------------------
    | Component: Gallery
    |----------------------------------------------------------------
    */
  .gallery .image-slider {
    display: flex;
    flex-wrap: wrap; }
    .gallery .image-slider .img {
      flex-basis: 31.3333333333%;
      max-width: 31.3333333333%;
      padding-bottom: 31.3333333333%; }
      .gallery .image-slider .img:nth-child(3n + 2) {
        margin: 0 2%; }
      .gallery .image-slider .img:nth-child(n+4) {
        margin-top: 25px; }
    .gallery .image-slider:after {
      content: ''; }
  .gform_fields {
    display: flex;
    flex-wrap: wrap; }
  .gfield {
    flex-basis: 100%;
    max-width: 100%; }
  .gf-half {
    flex-basis: 48%;
    max-width: 48%; }
  .gf-left {
    margin-right: 4%; }
  /*
    |----------------------------------------------------------------
    | Page: Search
    |----------------------------------------------------------------
    */
  .search-results .entry-image {
    height: auto;
    flex-basis: 30%;
    max-width: 30%;
    margin: 0 5% 0 0; }
  .search-results .entry-summary {
    flex-basis: 65%;
    max-width: 65%; }
  /*
    |----------------------------------------------------------------
    | Component: Content Table
    |----------------------------------------------------------------
    */
  .scg-list {
    margin: 15px 0 0 85px; }
    .scg-list li .is-right {
      float: right; }
  /*
    |----------------------------------------------------------------
    |  IATS Form
    |----------------------------------------------------------------
    */
  /*
    |----------------------------------------------------------------
    | Footer
    |----------------------------------------------------------------
    */
  .footer {
    padding-left: 30px;
    padding-right: 30px; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .footer {
      padding-left: calc(4.6296296296vw - 5.5555555556px); } }
  @media (min-width: 768px) and (min-width: 1200px) {
    .footer {
      padding-left: 50px; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .footer {
      padding-right: calc(4.6296296296vw - 5.5555555556px); } }
  @media (min-width: 768px) and (min-width: 1200px) {
    .footer {
      padding-right: 50px; } }

@media (min-width: 768px) {
    .footer .social-row {
      background: transparent; }
    .footer .social-col hr {
      bottom: -7vw;
      height: 18vw;
      transform: skewY(8.5deg) translateY(-50%) translateZ(0); }
    .footer .ftr-hr-end {
      transform: skewY(8.5deg) translateY(-50%) translateZ(0); } }

@media (min-width: 1200px) {
  /*
    |----------------------------------------------------------------
    | Header
    |----------------------------------------------------------------
    */
  header .home-link {
    position: absolute;
    top: 80px; }
    header .home-link img {
      width: 260px; }
  /*
    |----------------------------------------------------------------
    | Navigation
    |-----------------------------------------------------------------
    */
  .gn--trigger {
    display: none; }
  .gn-wrap,
  .gn {
    display: block;
    position: static;
    width: 100%; }
  .gn {
    overflow: visible !important;
    padding: 0;
    background: transparent;
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 20px; } }
  @media (min-width: 1200px) and (min-width: 1200px) {
    .gn {
      top: calc(1.3888888889vw + 3.3333333333px); } }
  @media (min-width: 1200px) and (min-width: 3000) {
    .gn {
      top: 45px; } }

@media (min-width: 1200px) {
    .gn .sub-menu .menu-item {
      padding-left: 0;
      margin: 0;
      border-bottom: 1px solid #14283a; }
      .gn .sub-menu .menu-item:first-child {
        padding: 0; }
      .gn .sub-menu .menu-item:last-child {
        border: none;
        padding: 0; }
  .gn--items {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: flex-end; }
    .gn--items .sub-trigger-wrap {
      display: none; }
    .gn--items .menu-item {
      opacity: 1;
      font-size: 14px;
      margin: 0;
      position: relative;
      text-transform: uppercase; }
      .gn--items .menu-item a {
        color: #14283a;
        padding: 10px 25px; }
      .gn--items .menu-item:hover {
        background: #508391; }
        .gn--items .menu-item:hover > a {
          color: #fff !important; }
        .gn--items .menu-item:hover .sub-menu {
          transition: .35s;
          transform: translateY(0);
          visibility: visible;
          opacity: 1; }
          .gn--items .menu-item:hover .sub-menu a {
            padding: 20px; }
    .gn--items .sub-menu {
      position: absolute;
      display: block !important;
      z-index: 10;
      width: auto;
      top: 35px;
      left: 0;
      min-width: 145%;
      transform: translateY(50%);
      transition-property: transform,opacity;
      visibility: hidden;
      opacity: 0;
      background: #508391;
      margin: 0;
      border: none; }
  .mobile-search {
    opacity: 0;
    position: absolute;
    right: -20px;
    top: 55px;
    transition: all .38s ease;
    visibility: hidden; }
    .mobile-search.is-showing {
      visibility: visible;
      opacity: 1;
      top: 25px; }
  .icon-holder {
    position: relative;
    width: 40px;
    height: 25px; }
    .icon-holder:focus {
      outline: 0; }
  .ico-mglass {
    position: relative;
    display: inline-block;
    border-radius: 30px;
    height: 18px;
    width: 18px;
    border: 1px solid #fff;
    margin-top: 5px; }
    .ico-mglass:after {
      content: "";
      height: 1px;
      width: 8px;
      background: #fff;
      position: absolute;
      top: 17px;
      left: 13px;
      transform: rotate(45deg); }
  /*
    |----------------------------------------------------------------
    | Template: Homepage
    |----------------------------------------------------------------
    */
  .page-banner .banner-content .last-updated {
    text-align: right; }
  /*
    |----------------------------------------------------------------
    | Template: Pilot
    |----------------------------------------------------------------
    */
  .downloads a.blue:hover {
    background: #508391; }
  .downloads a.turquoise:hover {
    background: #14283a; }
  /*
    |----------------------------------------------------------------
    | Component: Gallery
    |----------------------------------------------------------------
    */
  .gallery a:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #14283a;
    opacity: 0;
    transition: all .3s ease; }
  .gallery a:hover:before {
    opacity: .65; }
  /*
    |----------------------------------------------------------------
    | Footer
    |----------------------------------------------------------------
    */
  .footer .social-col .icon:hover {
    border-color: #14283a; }
    .footer .social-col .icon:hover svg {
      fill: #14283a !important; }
  .footer .social-col hr,
  .footer .ftr-hr-end {
    transform: skewY(6.5deg) translateY(-50%) translateZ(0); } }
