.button {
    font-size: 15px;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-shadow: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 22px;
    border-radius: 5px;
    border: 1px solid transparent;
    background: $primary;
    color: #f0f0f0;
    font-weight: $semi;
}
