.table-title {
    background: #50838E;
    color: $white;
    display: inline-block;
    width: auto;
    padding: 8px 12px;
    margin-bottom: 0;
}

.table-wrap {


    h2,h3,h4,h5,h6 {
        margin: 0 0 4px !important;
        color: #14283a;
    }

    .tr {
       display: grid;
       grid-template-columns: 1fr 1fr 1fr;

        &:nth-child(even) {
            background: #ebebeb;
        }
    }

    .th {
        background: #50838E;
        font-weight: 600;

        p {
            color: $white;
            font-size: 12px;
        }
    }

    .cell {
        padding: 12px;

        &:nth-child(4n + 2) {
            display: none;
        }

        p {
            font-size: 14px ;
        }
    }
}
