.search-pg {

    .search-terms {
        font-size: 18px;

        span {
            border-bottom: 1px solid $primary;
        }
    }
}


.search-results {
    margin-top: 60px;

    article {
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #ebebeb;
    }

    .entry-image {
        $height: (320px: 150px, 768px: 220px);
        @include poly-fluid-sizing('height', $height);
        margin-bottom: 30px;
    }

    .entry-title {
        $font-size: (320px: 22px, 1200px: 29px);
        @include poly-fluid-sizing('font-size', $font-size);
    }

    .learn-more {
        $font-size: (320px: 15px, 1200px: 18px);
        @include poly-fluid-sizing('font-size', $font-size);
        color: $blue;
        font-weight: $semi;
        padding-top: 25px;
        display: inline-block;
    }
}
