/*
|----------------------------------------------------------------
|  Fees
|----------------------------------------------------------------
*/

.fees-blk {

    .box-1 {

        .img-wrap,
        .fee-wrap {
            background: $blue;
        }

    }

    .box-2 {
        margin-top: 45px;

        .img-wrap,
        .fee-wrap {
            background: $primary;
        }
    }

    .img-wrap {
        width: 50px;
        height: 50px;
        padding: 12px;
    }

    .fee-wrap {
        text-align: center;
        padding: 30px 15px;

        * {
            color: $white;
        }
    }

    .fee-heading {
        margin-top: 0;
        $font-size: (320px: 17px, 1200px: 20px);
        @include poly-fluid-sizing('font-size', $font-size);
    }

    .fee {
        $font-size: (320px: 20px, 1200px: 35px);
        @include poly-fluid-sizing('font-size', $font-size);
        font-weight: $semi;
    }

    li {
        margin: 0;
        padding: 15px 0;
        text-align: center;
        color: $blue;

        &:nth-child(even) {
            background: #ebebeb;
        }
    }
}


/*
|----------------------------------------------------------------
|  General Information
|----------------------------------------------------------------
*/
.general-info {
    $margin-bottom: (320px: 55px, 1200px: 70px);
    @include poly-fluid-sizing('margin-bottom', $margin-bottom);
}
