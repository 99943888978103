// --------------------------------------------------------------------------
// Fonts Weight
// --------------------------------------------------------------------------
$thin    : 100;
$light   : 300;
$normal  : 400;
$regular : 400;
$medium  : 500;
$semi    : 600;
$bold    : 700;
$x-bold  : 900;


@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600');

@mixin montserratRegular() {
    font-weight: $normal;
}

@mixin montserratSemiBold() {
    font-weight: $semi;
}
