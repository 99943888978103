::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #c1c1c1;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #c1c1c1;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #c1c1c1;
}
:-moz-placeholder { /* Firefox 18- */
  color: #c1c1c1;
}

.gform_validation_container { // honeypot
    display: none !important;
}


[type="text"],
[type="url"],
[type="email"],
[type="tel"],
[type="number"],
[type="password"],
[type="search"],
select,
textarea {
    transition-duration: .28s;
    transition-property: background-color, color, border, opacity, transform;
    box-shadow: none;
    border-radius: 5px;
    outline: none;
    border: 1px solid #c1c1c1;
    background: white;
    padding: 8px 10px;
    width: 100%;
    appearance: none;

    &:focus {
        outline: none;
        background: #f0f0f0;
    }
}

select,
textarea {
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    padding: 10px 12px;
    color: $main-text;
}

textarea {
    height: 200px;
}

.gfield {

    &:not(.show-label) {

        .gfield_label{
            display: none;
        }
    }

    .gfield_required {
        position: relative;
        top: 9px;
        right: -2px;
        line-height: 1;
        font-size: 22px;
    }
}


.validation_error {
  background: red;
  color: $white;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 10px;
}

.validation_message {
  color: red;
  text-align: left;
  font-weight: 500;
  margin-top: 5px;
}

/*
|----------------------------------------------------------------
|  IATS Form
|----------------------------------------------------------------
*/



#IATS_PaymentBoxDiv {
    width: 100% !important;
}

.IATS_PaymentSectionTitle,
.IATS_AmountSectionTitle {
    color: #508391 !important;
}

#IATS_ProcessAction_Button {
    font-size: 15px;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-shadow: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 22px;
    border-radius: 5px;
    border: 1px solid transparent;
    background: $primary;
    color: #f0f0f0;
    font-weight: $semi;
}
