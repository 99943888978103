.gallery {
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;

    .image-slider {
        width: 100%;

        .img {
            width: 100%;
            padding-bottom: 100%;
            margin: 0;
            position: relative;
        }

        &:after {
            content: 'flickity';
            display: none;
        }
    }
}
